import React, {useState} from 'react'
import { Link } from 'react-router-dom';
import './First_block.css'
import Home from '../../pages/Home';

const Services_first_block = () => {
  const [menu, setMenu] = useState("home");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className='first-block'>
        <h1 className='page-name'>Services</h1>
        <li onClick={() => { setMenu("home"); setIsMenuOpen(false); }}><Link style={{ textDecoration: 'none' }} to="/">Home</Link> / Our Services</li>
    </div>
  )
}

export default Services_first_block