import React, { useState } from 'react';
import './Footer.css'
import facebook_icon from '../assets/facebook-icon.png'
import twitter_icon from '../assets/twitter-icon.png'
import linkedin_icon from '../assets/linkedin-icon.png'
import instagram_icon from '../assets/instagram-icon.png'
import { Link } from 'react-router-dom'
import copyright_icon from '../assets/copyright-icon.png'


const Footer = () => {

    const [menu, setMenu] = useState("home");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
    return (
        <div className='footer'>
            <div className="main-footer">
                <div className="column-1">
                    <h1>MK Pro</h1>
                    <p>Think of us as climbers. We know <br /> this mountain and it is a thrill to <br /> climb.</p>
                    <div className="icon-container">
                        <div className="icon-box">
                            <img src={facebook_icon} alt="" />
                        </div>
                        <div className="icon-box">
                            <img src={twitter_icon} alt="" />
                        </div>
                        <div className="icon-box">
                            <img src={instagram_icon} alt="" />
                        </div>
                        <div className="icon-box">
                            <img src={linkedin_icon} alt="" />
                        </div>
                    </div>
                </div>
                <div className="column-2">
                    <h3>USA</h3>
                    <p>617 Hoes Ln W Piscataway, NJ <br /> 08854</p>
                    <p>+1 (732) 971-8885</p>
                    <p>+1 (201) 710-0008</p>
                    <p>info@mkpro.co.uk</p>
                </div>
                <div className="column-3">
                <h3>UK</h3>
                    <p>834 Primrose Rd, E10 5EE, <br /> London</p>
                    <p>+44 (77) 4265-3133</p>
                    <p>info@mkpro.co.uk</p>
                </div>
                <div className="column-4">
                <h3>Quick Links</h3>
                    <li onClick={() => { setMenu("home"); setIsMenuOpen(false); }}><Link style={{textDecoration: 'none'}} to="/">Home</Link></li>
                    <li onClick={() => { setMenu("about"); setIsMenuOpen(false); }}><Link style={{textDecoration: 'none'}} to="/About">About Us</Link></li>
                    <li onClick={() => { setMenu("services"); setIsMenuOpen(false); }}><Link style={{textDecoration: 'none'}} to="/Services">Services</Link></li>
                    <li onClick={() => { setMenu("prices"); setIsMenuOpen(false); }}><Link style={{textDecoration: 'none'}} to="/Prices">Prices</Link></li>
                    <li onClick={() => { setMenu("faqs"); setIsMenuOpen(false); }}><Link style={{textDecoration: 'none'}} to="/Faqs">FAQs</Link></li>
                    <li onClick={() => { setMenu("contact"); setIsMenuOpen(false); }}><Link style={{textDecoration: 'none'}} to="/Contact">Contact Us</Link></li>
                    
                    
                    
                </div>
            </div><hr  className='footer-hr'/>
            <div className="copyright-line">
                <img src={copyright_icon} alt="" />
               <p><Link className='footer-link'>MK Pro Services</Link>,All Right Reserved.</p>
            </div>
        </div>
    )
}

export default Footer