import React from 'react'
import './css/Services.css'
import Services_first_block from '../components/all-pages-first-block/Services_first_block'
import service_icon_1 from '../components/assets/manager-icon.png'
import service_icon_2 from '../components/assets/software-icon.png'
import service_icon_3 from '../components/assets/claim-business-icon.png'
import service_icon_4 from '../components/assets/payment-icon.png'
import service_icon_5 from '../components/assets/follow-up-icon.png'
import service_icon_6 from '../components/assets/report-icon.png'

const Services = () => {
  return (
    <div>
      <Services_first_block />
      <div className="service-paragraph">
        <h1>Comprehensive medical billing services for your <br /> practice</h1>
        <p>We provide comprehensive medical billing services at competitive prices nationwide, with state-specific billing <br /> rules knowledge. We offer a flexible month-to-month contract, no setup fee, and a 30-day free trial. We are <br /> experienced with all major medical billing software and work with any software you have. If you do not have <br /> any, we will recommend you one that fits your needs and budget. The onboarding process is quick and simple, <br /> and takes 2 to 5 business days. We offer complete revenue cycle management services including:</p>
      </div>


<div className="services-cards">
      <div className="cards">
          <div className="card">
            <div className="services-heading-div">
              <div className="servic-icon-div">
                <div className="service-icon">
                  <img src={service_icon_1} alt="" />
                </div>
              </div>
              <h2>Dedicated billing <br /> manager</h2>
            </div>
            <p>You will be assigned a dedicated <br /> billing manager to ensure a smooth <br /> transition and day-to-day billing. <br /> Your billing manager is available via <br /> email and phone during business <br /> hours.</p>
          </div>
          <div className="card">
            <div className="services-heading-div">
              <div className="servic-icon-div">
                <div className="service-icon">
                  <img src={service_icon_2} alt="" />
                </div>
              </div>
              <h2>Working with <br /> your software</h2>
            </div>
            <p>We work remotely with any EHR and <br /> PM software and clearinghouse you <br /> have. We handle both online and <br /> server-based software. If you do not <br /> have any, we will recommend one <br /> that fits your needs and budget.</p>
          </div>
          <div className="card">
            <div className="services-heading-div">
              <div className="servic-icon-div">
                <div className="service-icon">
                  <img src={service_icon_3} alt="" />
                </div>
              </div>
              <h2>Claim <br /> Submission</h2>
            </div>
            <p>Claims are scrubbed and submitted <br /> daily: error-free, 98% first-time pass <br /> rate. Rejections are corrected and <br /> resubmitted promptly.</p>
          </div>
        </div>

        <div className="cards">
          <div className="card">
            <div className="services-heading-div">
              <div className="servic-icon-div">
                <div className="service-icon">
                  <img src={service_icon_4} alt="" />
                </div>
              </div>
              <h2>Payment <br /> Posting</h2>
            </div>
            <p>The billing team verifies the <br /> appropriate payment for each claim <br /> and promptly posts all ERAs and <br /> EOBs. The remaining balance is billed <br /> to the secondary insurance or <br /> posted to the patient's account.</p>
          </div>
          <div className="card">
            <div className="services-heading-div">
              <div className="servic-icon-div">
                <div className="service-icon">
                  <img src={service_icon_5} alt="" />
                </div>
              </div>
              <h2>Follow up and <br /> appeals</h2>
            </div>
            <p>We follow up on every claim that is <br /> not paid within 30 days by <br /> contacting the insurance companies. <br /> If the claim is denied, we review, <br /> correct, and resubmit it immediately.</p>
          </div>
          <div className="card">
            <div className="services-heading-div">
              <div className="servic-icon-div">
                <div className="service-icon">
                  <img src={service_icon_6} alt="" />
                </div>
              </div>
              <h2>Customized <br /> Reports</h2>
            </div>
            <p>The reports are provided weekly <br /> and/or monthly and they are <br /> customized to your preferences. <br /> These reports include transmission, <br /> payments, A/R, denials, and more.</p>
          </div>
        </div>
      <div className="cards">
          <div className="card">
            <div className="services-heading-div">
              <div className="servic-icon-div">
                <div className="service-icon">
                  <img src={service_icon_1} alt="" />
                </div>
              </div>
              <h2>Dedicated billing <br /> manager</h2>
            </div>
            <p>You will be assigned a dedicated <br /> billing manager to ensure a smooth <br /> transition and day-to-day billing. <br /> Your billing manager is available via <br /> email and phone during business <br /> hours.</p>
          </div>
          <div className="card">
            <div className="services-heading-div">
              <div className="servic-icon-div">
                <div className="service-icon">
                  <img src={service_icon_2} alt="" />
                </div>
              </div>
              <h2>Working with <br /> your software</h2>
            </div>
            <p>We work remotely with any EHR and <br /> PM software and clearinghouse you <br /> have. We handle both online and <br /> server-based software. If you do not <br /> have any, we will recommend one <br /> that fits your needs and budget.</p>
          </div>
          <div className="card">
            <div className="services-heading-div">
              <div className="servic-icon-div">
                <div className="service-icon">
                  <img src={service_icon_3} alt="" />
                </div>
              </div>
              <h2>Claim <br /> Submission</h2>
            </div>
            <p>Claims are scrubbed and submitted <br /> daily: error-free, 98% first-time pass <br /> rate. Rejections are corrected and <br /> resubmitted promptly.</p>
          </div>
        </div>

        <div className="cards">
          <div className="card">
            <div className="services-heading-div">
              <div className="servic-icon-div">
                <div className="service-icon">
                  <img src={service_icon_4} alt="" />
                </div>
              </div>
              <h2>Payment <br /> Posting</h2>
            </div>
            <p>The billing team verifies the <br /> appropriate payment for each claim <br /> and promptly posts all ERAs and <br /> EOBs. The remaining balance is billed <br /> to the secondary insurance or <br /> posted to the patient's account.</p>
          </div>
          <div className="card">
            <div className="services-heading-div">
              <div className="servic-icon-div">
                <div className="service-icon">
                  <img src={service_icon_5} alt="" />
                </div>
              </div>
              <h2>Follow up and <br /> appeals</h2>
            </div>
            <p>We follow up on every claim that is <br /> not paid within 30 days by <br /> contacting the insurance companies. <br /> If the claim is denied, we review, <br /> correct, and resubmit it immediately.</p>
          </div>
          <div className="card">
            <div className="services-heading-div">
              <div className="servic-icon-div">
                <div className="service-icon">
                  <img src={service_icon_6} alt="" />
                </div>
              </div>
              <h2>Customized <br /> Reports</h2>
            </div>
            <p>The reports are provided weekly <br /> and/or monthly and they are <br /> customized to your preferences. <br /> These reports include transmission, <br /> payments, A/R, denials, and more.</p>
          </div>
        </div>


        </div>
    </div>
  )
}

export default Services