import React from 'react'
import './css/Prices.css'
import Prices_first_block from '../components/all-pages-first-block/Prices_first_block'

const Prices = () => {
  return (
    <div>
      <Prices_first_block />
      <div className="prices-cards">
        <h1>Our Prices</h1>
        <div className="cards">
          <div className="card">
            <h2 className='b-heading'>Focus on Patient Care</h2>
            <p className='b'>Let us handle your financial burdens <br /> while you handle what matters most: <br /> your patients.</p>
          </div>
          <div className="card">
            <h2 className='b-heading'>Increase Billing</h2>
            <p className='b'>Our billing specialists ensure <br /> accurate and timely submission of <br /> claims, helping you maximize <br /> reimbursements while reducing the <br /> number of rejections and denials.</p>
          </div>
          <div className="card">
            <h2 className='b-heading'>Improve Cash Flow</h2>
            <p className='b'>We help you acquire a steady, <br /> continuous cash flow by offering an <br /> interruption-free service and daily <br /> claim submission, as well as following <br /> up on all aging A/R.</p>
          </div>
        </div>
        <div className="cards">
          <div className="card">
            <h2 className='b-heading'> Operational Expenses</h2>
            <p className='b'>Instead of spending thousands on <br /> training, salaries, and benefits, the <br /> only cost you will have to worry <br /> about is our collections-based <br /> pricing.</p>
          </div>
          <div className="card">
            <h2 className='b-heading'>Patient Satisfaction</h2>
            <p className='b'>Without the challenge of dealing with <br /> billing, your staff can instead focus <br /> on your patients.</p>
          </div>
          <div className="card">
            <h2 className='b-heading'>Ensure Compliance</h2>
            <p className='b'>Our experienced and well-trained <br /> staff ensures compliance with an <br /> ever-changing payer policy.</p>
          </div>
        </div>
        <div className="cards">
          <div className="card">
            <h2 className='b-heading'>Focus on Patient Care</h2>
            <p className='b'>Let us handle your financial burdens <br /> while you handle what matters most: <br /> your patients.</p>
          </div>
          <div className="card">
            <h2 className='b-heading'>Increase Billing</h2>
            <p className='b'>Our billing specialists ensure <br /> accurate and timely submission of <br /> claims, helping you maximize <br /> reimbursements while reducing the <br /> number of rejections and denials.</p>
          </div>
          <div className="card">
            <h2 className='b-heading'>Improve Cash Flow</h2>
            <p className='b'>We help you acquire a steady, <br /> continuous cash flow by offering an <br /> interruption-free service and daily <br /> claim submission, as well as following <br /> up on all aging A/R.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Prices