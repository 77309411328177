import React from 'react'
import './css/Contact.css'
import Contact_first_block from '../components/all-pages-first-block/Contact_first_block'
import mobile_icon from '../components/assets/mobile-icon.png'
import email_icon from '../components/assets/email-icon.png'
import address_icon from '../components/assets/address-icon.png'

const Contact = () => {
  return (
    <div>
      <Contact_first_block />
      <div className="contact-box">
        <div className="contact-left">
            <h1>If You Have Any Query, <br /> Please Contact Us</h1>
            <h3>USA</h3>
            <div className="address">
              <img src={address_icon} alt="" />
              <p>617 Hoes Ln W Piscataway, NJ 08854</p>
            </div>
            <div className="address">
              <img src={mobile_icon} alt="" />
              <p>+1 (732) 971-8885   /   +1 (201) 710-0008</p>
            </div>
            <div className="address">
              <img src={email_icon} alt="" />
              <p>info@mkpro.co.uk</p>
            </div>
            <h3>UK</h3>
            <div className="address">
              <img src={address_icon} alt="" />
              <p>834 Primrose Rd, E10 5EE, London</p>
            </div>
            <div className="address">
              <img src={mobile_icon} alt="" />
              <p>+44 (77) 4265-3133</p>
            </div>
            <div className="address">
              <img src={email_icon} alt="" />
              <p>info@mkpro.co.uk</p>
            </div>
        </div>
        <div className="contact-right">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39714.575806776935!2d-0.14285304547002498!3d51.52860701284126!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761b33a2fdf939%3A0x784b6953c2310702!2sDottore%20London%20-%20Italian%20Medical%20Centre%20in%20London!5e0!3m2!1sen!2s!4v1724273124249!5m2!1sen!2s" width="550" height="550" allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
    </div>
  )
}

export default Contact