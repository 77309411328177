import React, { useState } from 'react';
import './Navbar.css';
import logo from '../assets/logo-1.png'
import { Link } from 'react-router-dom';

const Navbar = () => {
    const [menu, setMenu] = useState("home");
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div className='nav'>
            <div className="logo">
                <img src={logo} alt="Logo" />
            </div>
            
            <ul className={`menu ${isMenuOpen ? 'active' : ''}`}>
                <li onClick={() => { setMenu("home"); setIsMenuOpen(false); }}><Link style={{textDecoration: 'none'}} to="/">Home</Link>{menu === "home" ? <hr /> : <></>}</li>
                <li onClick={() => { setMenu("about"); setIsMenuOpen(false); }}><Link style={{textDecoration: 'none'}} to="/About">About Us</Link>{menu === "about" ? <hr /> : <></>}</li>
                <li onClick={() => { setMenu("services"); setIsMenuOpen(false); }}><Link style={{textDecoration: 'none'}} to="/Services">Services</Link>{menu === "services" ? <hr /> : <></>}</li>
                <li onClick={() => { setMenu("prices"); setIsMenuOpen(false); }}><Link style={{textDecoration: 'none'}} to="/Prices">Prices</Link>{menu === "prices" ? <hr /> : <></>}</li>
                <li onClick={() => { setMenu("faqs"); setIsMenuOpen(false); }}><Link style={{textDecoration: 'none'}} to="/Faqs">FAQs</Link>{menu === "faqs" ? <hr /> : <></>}</li>
                <li onClick={() => { setMenu("contact"); setIsMenuOpen(false); }}><Link style={{textDecoration: 'none'}} to="/Contact">Contact Us</Link>{menu === "contact" ? <hr /> : <></>}</li>
            </ul>
            <div className={`hamburger ${isMenuOpen ? 'active' : ''}`} onClick={toggleMenu}>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};

export default Navbar;



