
import './App.css';
import Navbar from './components/navbar/Navbar';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import About from './pages/About';
import Home from './pages/Home';
import Services from './pages/Services';
import Prices from './pages/Prices';
import Faqs from './pages/Faqs';
import Contact from './pages/Contact';
import Hero_nav from './components/hero-nav/Hero_nav';
import Footer from './components/footer/Footer';


function App() {
  return (
    <div>
      <BrowserRouter>
        <Hero_nav />
        <Navbar />
        
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/services' element={<Services />} />
          <Route path='/prices' element={<Prices />} />
          <Route path='/faqs' element={<Faqs />} />
          <Route path='/contact' element={<Contact />} />
        </Routes>
        
        <Footer />

      </BrowserRouter>


    </div>
  );
}

export default App;
