import React, { useState, useEffect } from 'react';
import './css/Home.css';
import Hero from '../components/hero/Hero';
import img_1 from '../components/assets/hero-1.jpg';
import img_2 from '../components/assets/hero-2.jpg';
import img_3 from '../components/assets/revenue-icon.png';
import img_4 from '../components/assets/billing-icon.png';
import img_5 from '../components/assets/team-icon.png';
import img_6 from '../components/assets/pricing-icon.png';
import img_7 from '../components/assets/about-img.jpg';
import { Link } from 'react-router-dom';
import service_icon_1 from '../components/assets/manager-icon.png'
import service_icon_2 from '../components/assets/software-icon.png'
import service_icon_3 from '../components/assets/claim-business-icon.png'
import service_icon_4 from '../components/assets/payment-icon.png'
import service_icon_5 from '../components/assets/follow-up-icon.png'
import service_icon_6 from '../components/assets/report-icon.png'



const Home = () => {

  const [menu, setMenu] = useState("home");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };  
  // Array to hold the images
  const images = [img_1, img_2];
  const [currentIndex, setCurrentIndex] = useState(0);

  // Function to go to the next slide
  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  // Function to go to the previous slide
  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };


  useEffect(() => {
    const interval = setInterval(nextSlide, 3000); // Change slide every 3 seconds
    return () => clearInterval(interval); // Cleanup on component unmount
  }, []);

  return (
    <div>
      {/* slider */}
      <div className="slider">
        <button className="nav-button prev" onClick={prevSlide}>‹</button>

        <img src={images[currentIndex]} alt="Slider" className="slider-image" />
        <button className="nav-button next" onClick={nextSlide}>›</button>
      </div>


      <Hero />


      {/* about-box */}
      <div className="about-block">
        <div className="about-left">
          <h1>Why MK Pro Services?</h1>
          <div className="block">
            <div className="about-box">
              <div className="image-box">
                <img src={img_3} alt="" />
              </div>
              <p>Increase Revenue</p>

            </div>
            <div className="about-box">
              <div className="image-box">
                <img src={img_4} alt="" />
              </div>
              <p>Multi-Speciality <br /> Billing</p>

            </div>
            <div className="about-box">
              <div className="image-box">
                <img src={img_5} alt="" />
              </div>
              <p>Dedicated Team</p>

            </div>
            <div className="about-box">
              <div className="image-box">
                <img src={img_6} alt="" />
              </div>
              <p>Pricing Options</p>

            </div>
          </div>
        </div>
        <div className="about-right">
          <img src={img_7} alt="" />
        </div>
      </div>
      <div className="btn">
        <button className='about-us-btn'> <li onClick={() => { setMenu("about"); setIsMenuOpen(false); }}><Link style={{ textDecoration: 'none' }} to="/About">About Us</Link></li></button>

      </div>

      {/* more-services */}
      <div className="more-services">
        <div className="heading">
          <h1>Comprehensive medical billing services <br /> for your practice</h1>
        </div>
        <div className="cards">
          <div className="card">
            <div className="services-heading-div">
              <div className="servic-icon-div">
                <div className="service-icon">
                  <img src={service_icon_1} alt="" />
                </div>
              </div>
              <h2>Dedicated billing <br /> manager</h2>
            </div>
            <p>You will be assigned a dedicated <br /> billing manager to ensure a smooth <br /> transition and day-to-day billing. <br /> Your billing manager is available via <br /> email and phone during business <br /> hours.</p>
          </div>
          <div className="card">
            <div className="services-heading-div">
              <div className="servic-icon-div">
                <div className="service-icon">
                  <img src={service_icon_2} alt="" />
                </div>
              </div>
              <h2>Working with <br /> your software</h2>
            </div>
            <p>We work remotely with any EHR and <br /> PM software and clearinghouse you <br /> have. We handle both online and <br /> server-based software. If you do not <br /> have any, we will recommend one <br /> that fits your needs and budget.</p>
          </div>
          <div className="card">
            <div className="services-heading-div">
              <div className="servic-icon-div">
                <div className="service-icon">
                  <img src={service_icon_3} alt="" />
                </div>
              </div>
              <h2>Claim <br /> Submission</h2>
            </div>
            <p>Claims are scrubbed and submitted <br /> daily: error-free, 98% first-time pass <br /> rate. Rejections are corrected and <br /> resubmitted promptly.</p>
          </div>

        </div>
        <div className="cards">
          <div className="card">
            <div className="services-heading-div">
              <div className="servic-icon-div">
                <div className="service-icon">
                  <img src={service_icon_4} alt="" />
                </div>
              </div>
              <h2>Payment <br /> Posting</h2>
            </div>
            <p>The billing team verifies the <br /> appropriate payment for each claim <br /> and promptly posts all ERAs and <br /> EOBs. The remaining balance is billed <br /> to the secondary insurance or <br /> posted to the patient's account.</p>
          </div>
          <div className="card">
            <div className="services-heading-div">
              <div className="servic-icon-div">
                <div className="service-icon">
                  <img src={service_icon_5} alt="" />
                </div>
              </div>
              <h2>Follow up and <br /> appeals</h2>
            </div>
            <p>We follow up on every claim that is <br /> not paid within 30 days by <br /> contacting the insurance companies. <br /> If the claim is denied, we review, <br /> correct, and resubmit it immediately.</p>
          </div>
          <div className="card">
            <div className="services-heading-div">
              <div className="servic-icon-div">
                <div className="service-icon">
                  <img src={service_icon_6} alt="" />
                </div>
              </div>
              <h2>Customized <br /> Reports</h2>
            </div>
            <p>The reports are provided weekly <br /> and/or monthly and they are <br /> customized to your preferences. <br /> These reports include transmission, <br /> payments, A/R, denials, and more.</p>
          </div>

        </div>
        <div className="service-btn">
          <button className='about-us-btn'> <li onClick={() => { setMenu("services"); setIsMenuOpen(false); }}><Link style={{ textDecoration: 'none' }} to="/Services">More Services</Link></li></button>

        </div>

      </div>


      {/* our-prices */}
      <div className="prices-block">
        <div className="price-left">
          <img src={img_7} alt="" />
        </div>
        <div className="price-right">
          <h1>All Prices</h1>
          <div className="price-block">
            <div className="price-box">
              <div className="image-box">
                <img src={img_3} alt="" />
              </div>
              <p>Increase Revenue</p>

            </div>
            <div className="price-box">
              <div className="image-box">
                <img src={img_4} alt="" />
              </div>
              <p>Multi-Speciality <br /> Billing</p>

            </div>
            <div className="price-box">
              <div className="image-box">
                <img src={img_5} alt="" />
              </div>
              <p>Dedicated Team</p>

            </div>
            <div className="price-box">
              <div className="image-box">
                <img src={img_6} alt="" />
              </div>
              <p>Pricing Options</p>

            </div>
          </div>

        </div>
      </div>
      <div className="prices-btn">
        <button className='about-us-btn'> <li onClick={() => { setMenu("prices"); setIsMenuOpen(false); }}><Link style={{ textDecoration: 'none' }} to="/Prices">All Prices</Link></li></button>

      </div>
      
    </div>
  );
};

export default Home;
